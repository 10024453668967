import ky from 'ky';
import { HttpClient } from '~/restAPI/http-client';
import { Catalog } from '~/restAPI/Catalog';
import { Home } from '~/restAPI/Home';
import { Products } from '~/restAPI/Products';

import { createHttpErrorHook, logRequestApiUrlHook } from './hooks';

export function useApi(newBaseUrl?: string) {
  const event = useRequestEvent();

  const httpClient = ky.create({
    timeout: 60000,
    retry: 1,
    hooks: {
      beforeError: [createHttpErrorHook],
      beforeRequest: [logRequestApiUrlHook],
      // КОСТЫЛЬ для проставления кук
      // Нужно для сторисов
      // afterResponse: [
      //   (_request, _options, response) => {
      //     if (process.server) {
      //       const combinedCookie = response.headers.get('set-cookie');

      //       if (combinedCookie && event) {
      //         event.node.res.setHeader('set-cookie', combinedCookie);
      //       }
      //     }
      //   },
      // ],
    },
  });

  const config = useRuntimeConfig();
  const headers = process.server ? useRequestHeaders([
    'cookie',
    'user-agent',
  ]) : undefined;

  return {
    createApiInstance<C extends typeof HttpClient>(ApiConstructor: C): C['prototype'] {
      const API_PATH = process.server ? config.API_PATH : undefined;
      let baseUrl = newBaseUrl ?? (API_PATH ?? '');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (process.server && config.API_PATH_CATALOG && (ApiConstructor === Catalog || ApiConstructor === Home || ApiConstructor === Products)) {
        baseUrl = config.API_PATH_CATALOG;
      }

      return new ApiConstructor({
        customFetch: httpClient || undefined,
        baseUrl,
        baseApiParams: {
          headers: headers as HeadersInit | undefined,
          format: 'json',
        },
      });
    },
  };
}
