import { default as _12storeezCEr1XBPzDRMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/12storeez.vue?macro=true";
import { default as favoritesv4HUvFB1auMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/favorites.vue?macro=true";
import { default as indexnONaWK4M69Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/index.vue?macro=true";
import { default as ordersN6lAg5GMpCMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/orders.vue?macro=true";
import { default as productsXqLpoHJ6hDMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/products.vue?macro=true";
import { default as salescGGeL1LZO1Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/sales.vue?macro=true";
import { default as setupNotificationsifFPyolAS3Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/setupNotifications.vue?macro=true";
import { default as support_45chatHoo8e9BBnAMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account/support-chat.vue?macro=true";
import { default as accountrWaTrbJuwnMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/account.vue?macro=true";
import { default as _91brand_93Cbph70QR3pMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[[category]]/[brand].vue?macro=true";
import { default as _91category_93A4Pq0F9as0Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[category].vue?macro=true";
import { default as cartMK3AbecXJZMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/cart.vue?macro=true";
import { default as _91_46_46_46catalog_93HiN10xJQY9Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/catalog/[...catalog].vue?macro=true";
import { default as checkoutny9HcvFmgjMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/checkout.vue?macro=true";
import { default as conciergeGDzwAnPMP6Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/concierge.vue?macro=true";
import { default as _91homeBannerId_938o2QAhM6YhMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/homeBanner/[homeBannerId].vue?macro=true";
import { default as indexOHLNSFu8R2Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/index.vue?macro=true";
import { default as aboutWbZjIlgUfyMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/about.vue?macro=true";
import { default as buyeriGaTIplmp3Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/buyer.vue?macro=true";
import { default as guaranteesQ6P4ukLMEHMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/guarantees.vue?macro=true";
import { default as kontakty88gLHAmIVJMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/kontakty.vue?macro=true";
import { default as ofertaVkcdqhhweKMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/oferta.vue?macro=true";
import { default as pressZqu4nQHvRHMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/press.vue?macro=true";
import { default as sellerbOwxuvNpC4Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/info/seller.vue?macro=true";
import { default as kidsktE2gt4pnZMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/kids.vue?macro=true";
import { default as lifestyleZdQporhbXKMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/lifestyle.vue?macro=true";
import { default as loginUhr5VTSYdrMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/login.vue?macro=true";
import { default as menf4HlARQYP2Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/men.vue?macro=true";
import { default as _91id_93D5T4XMM9xeMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/opinions/[id].vue?macro=true";
import { default as indexMlLUJXreXBMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/index.vue?macro=true";
import { default as thanksr7YFIN2KS2Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/thanks.vue?macro=true";
import { default as feedeVUa5PVxwPMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/osocial/feed.vue?macro=true";
import { default as feeduGJCNsaovoMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/otrends/feed.vue?macro=true";
import { default as _91product_93rYyo0sS413Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/products/[product].vue?macro=true";
import { default as indexzGdA3SQnqRMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/index.vue?macro=true";
import { default as wishlistEyCNW5XZQQMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/wishlist.vue?macro=true";
import { default as _91profileId_93PssI6OgWWAMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId].vue?macro=true";
import { default as indexkusVu0PLmyMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/profile/index.vue?macro=true";
import { default as konciergesDowZSTDHmMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/promo/koncierge.vue?macro=true";
import { default as vtb7QUnP5mmlhMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/promo/vtb.vue?macro=true";
import { default as publicationxlEpOCSZF6Meta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/publication.vue?macro=true";
import { default as uikit7CqsqpK9JvMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/uikit.vue?macro=true";
import { default as womencV2muBm1TWMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/women.vue?macro=true";
import { default as ya_45authVS2RvS56riMeta } from "/builds/oskelly.ru/oskelly-site-frontend/pages/ya-auth.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: "12storeez",
    path: "/12storeez",
    meta: _12storeezCEr1XBPzDRMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/12storeez.vue")
  },
  {
    name: accountrWaTrbJuwnMeta?.name,
    path: "/account",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account.vue"),
    children: [
  {
    name: "account-favorites",
    path: "favorites",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/favorites.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/index.vue")
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/orders.vue")
  },
  {
    name: "account-products",
    path: "products",
    meta: productsXqLpoHJ6hDMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/products.vue")
  },
  {
    name: "account-sales",
    path: "sales",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/sales.vue")
  },
  {
    name: "account-setupNotifications",
    path: "setupNotifications",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/setupNotifications.vue")
  },
  {
    name: "account-support-chat",
    path: "support-chat",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/account/support-chat.vue")
  }
]
  },
  {
    name: "brands-category-brand",
    path: "/brands/:category?/:brand()",
    meta: _91brand_93Cbph70QR3pMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[[category]]/[brand].vue")
  },
  {
    name: "brands-category",
    path: "/brands/:category()",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/brands/[category].vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/cart.vue")
  },
  {
    name: "catalog-catalog",
    path: "/catalog/:catalog(.*)*",
    meta: _91_46_46_46catalog_93HiN10xJQY9Meta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/catalog/[...catalog].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutny9HcvFmgjMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/checkout.vue")
  },
  {
    name: "concierge",
    path: "/concierge",
    meta: conciergeGDzwAnPMP6Meta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/concierge.vue")
  },
  {
    name: "homeBanner-homeBannerId",
    path: "/homeBanner/:homeBannerId()",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/homeBanner/[homeBannerId].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexOHLNSFu8R2Meta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/index.vue")
  },
  {
    name: "info-about",
    path: "/info/about",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/about.vue")
  },
  {
    name: "info-buyer",
    path: "/info/buyer",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/buyer.vue")
  },
  {
    name: "info-guarantees",
    path: "/info/guarantees",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/guarantees.vue")
  },
  {
    name: "info-kontakty",
    path: "/info/kontakty",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/kontakty.vue")
  },
  {
    name: "info-oferta",
    path: "/info/oferta",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/oferta.vue")
  },
  {
    name: "info-press",
    path: "/info/press",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/press.vue")
  },
  {
    name: "info-seller",
    path: "/info/seller",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/info/seller.vue")
  },
  {
    name: "kids",
    path: "/kids",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/kids.vue")
  },
  {
    name: "lifestyle",
    path: "/lifestyle",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/lifestyle.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/login.vue")
  },
  {
    name: "men",
    path: "/men",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/men.vue")
  },
  {
    name: "opinions-id",
    path: "/opinions/:id()",
    meta: _91id_93D5T4XMM9xeMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/opinions/[id].vue")
  },
  {
    name: "orders-orderId",
    path: "/orders/:orderId()",
    meta: indexMlLUJXreXBMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/index.vue")
  },
  {
    name: "orders-orderId-thanks",
    path: "/orders/:orderId()/thanks",
    meta: thanksr7YFIN2KS2Meta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/orders/[orderId]/thanks.vue")
  },
  {
    name: "osocial-feed",
    path: "/osocial/feed",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/osocial/feed.vue")
  },
  {
    name: "otrends-feed",
    path: "/otrends/feed",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/otrends/feed.vue")
  },
  {
    name: "products-product",
    path: "/products/:product()",
    meta: _91product_93rYyo0sS413Meta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/products/[product].vue")
  },
  {
    name: _91profileId_93PssI6OgWWAMeta?.name,
    path: "/profile/:profileId()",
    meta: _91profileId_93PssI6OgWWAMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId].vue"),
    children: [
  {
    name: "profile-profileId",
    path: "",
    meta: indexzGdA3SQnqRMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/index.vue")
  },
  {
    name: "profile-profileId-wishlist",
    path: "wishlist",
    meta: wishlistEyCNW5XZQQMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/[profileId]/wishlist.vue")
  }
]
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexkusVu0PLmyMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/profile/index.vue")
  },
  {
    name: "promo-koncierge",
    path: "/promo/koncierge",
    meta: konciergesDowZSTDHmMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/promo/koncierge.vue")
  },
  {
    name: "promo-vtb",
    path: "/promo/vtb",
    meta: vtb7QUnP5mmlhMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/promo/vtb.vue")
  },
  {
    name: "publication",
    path: "/publication",
    meta: publicationxlEpOCSZF6Meta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/publication.vue")
  },
  {
    name: "uikit",
    path: "/uikit",
    meta: uikit7CqsqpK9JvMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/uikit.vue")
  },
  {
    name: "women",
    path: "/women",
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/women.vue")
  },
  {
    name: "ya-auth",
    path: "/ya-auth",
    meta: ya_45authVS2RvS56riMeta || {},
    component: () => import("/builds/oskelly.ru/oskelly-site-frontend/pages/ya-auth.vue")
  },
  {
    name: "community-about",
    path: "/community/about",
    meta: { ...(indexp9V2p5fxycMeta || {}), ...{"communityDialog":{"ownerMode":false}} },
    component: () => import("~/pages/index.vue")
  },
  {
    name: "community-status",
    path: "/community/status",
    meta: { ...(indexp9V2p5fxycMeta || {}), ...{"communityDialog":{"ownerMode":true}} },
    component: () => import("~/pages/index.vue")
  },
  {
    name: "community-landing",
    path: "/community/landing",
    meta: { ...(indexp9V2p5fxycMeta || {}), ...{"communityDialog":{}} },
    component: () => import("~/pages/index.vue")
  }
]